import React, { Component } from 'react';
import './base.css';

class Footer extends Component {
    anoCorrente = new Date().getFullYear();
    btn_inscr = "Inscrever-se";

    handleSubmit = async (event) => {
        event.preventDefault();
        const email = event.target.EMAIL.value;
        const nome = "BRaNaTi Page";
        const mensagem = "Usuário se inscreveu no site! Oba!";
        // https://backend-sendmail.branati.eti.br/send_email
        try {
            const url = process.env.REACT_APP_BACKEND_SENDEMAIL_URL || "https://backend-sendmail.branati.eti.br/send_email";
            console.log(url);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nome, email, mensagem }),
            });

            const data = await response.json();
            console.log(data);
            // Faça algo após a chamada ser bem sucedida
        } catch (error) {
            console.error("Erro ao enviar o formulário:", error);
        }
    };

    render() {
        return (
            <footer>
                <div className="row footer-main">
                    <div className="col-six tab-full left footer-desc">
                        <div className="footer-logo"></div>
                        Agradeço primeiramente a Deus e minha família por tudo.
                    </div>
                    <div className="col-six tab-full right footer-subscribe">
                        <h4>Ser Notificado?</h4>
                        <p>Quando houverem atualizações, deseja receber? Coloque seu email abaixo.</p>
                        <div className="subscribe-form">
                            <form id="mc-form" className="group" noValidate={true} onSubmit={this.handleSubmit}>
                                <input type="email" defaultValue="" name="EMAIL" className="email" id="mc-email" placeholder="Email Address" required=""/>
                                <input type="submit" name="subscribe" defaultValue={this.btn_inscr}/>
                                <label htmlFor="mc-email" className="subscribe-message"></label>
                            </form>
                        </div>
                    </div>
                </div> 
                <div className="row footer-bottom">
                    <div className="col-twelve">
                        <div className="copyright">
                            <span>© Copyright {this.anoCorrente}</span> 
                        </div>
                        <div className="go-top">
                            <a className="smoothscroll" title="Back to Top" href="#top" aria-label="Descrição do Link"><i className="icon-arrow-up" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div> 
            </footer>
        );
    }
}

export default Footer;
