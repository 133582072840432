import './base.css';


function About() {
    let anoCorrente = new Date().getFullYear(); 
    let inicio = 1997; // substitua por seu número
    let tempo_experiencia = anoCorrente - inicio;
    let anos_codando = anoCorrente - 2000;
    // console.log(tempo_experiencia); // imprime o resultado

    return (
        <section id='about' className="s-about">
            <div className="row section-header has-bottom-sep" data-aos="fade-up">
                <div className="col-full">
                    <h3 className="subhead subhead--dark">Quem sou?</h3>
                    <h1 className="display-1 display-1--light">Renato Cunha</h1>
                    <img width="120" src={process.env.PUBLIC_URL + '/images/Renato_Cunha_500x500.png'} alt="Logo" />
                </div>
            </div>
            <div className="row about-desc" data-aos="fade-up">
                <div className="col-full">
                    <p>
                        Atuo como um arquiteto de TI que considero versátil, pois desde 1997 trabalho na área e passei por muitos departamentos e conheço muitas tecnologias. Sou mestre em sistemas mecatrônicos, tenho um MBA em governança de tecnologia da informação, formado em análise de sistemas e técnico em telecomunicações.
                        Meu foco hoje é em arquitetar e desenvolver aplicações para resolver problemas. </p>
                    <p> 
                        Em cada jornada evoluímos uma área de nossas vidas e acredito que um aprendizado, <b>consistente e contínuo</b>, pode formar um bom profissional.
                    </p>

                </div>
            </div>
            <div className="row about-stats stats block-1-2 block-m-1-2 block-mob-full" data-aos="fade-up">
                <div className="col-block stats__col ">
                    <div className="stats__count">{tempo_experiencia}</div>
                    <h5>Anos trabalhando com TI</h5>
                </div>
                <div className="col-block stats__col ">
                    <div className="stats__count">{anos_codando}</div>
                    <h5>Anos desenvolvendo software</h5>
                </div>
            </div> 
            <div className="about__line"></div>
        </section>
    )
}

export default About;
