//import logo from './logo.svg';
// import logo from './images/logo.png';
import { useEffect, useState } from 'react';
import './base.css';
import './fonts.css';
import './main.css';

import $ from 'jquery';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const menuTrigger = $('.header-menu-toggle');
    const nav = $('.header-nav');
    const closeButton = nav.find('.header-nav__close');
    const siteBody = $('body');
    const mainContents = $('section, footer');

    menuTrigger.on('click', function(e) {
        e.preventDefault();
        siteBody.toggleClass('menu-is-open');
    });

    return () => {
        // Limpar o evento ao desmontar o componente
        menuTrigger.off('click');
    };
}, []);

  const handleMenuToggle = (e) => {
      console.log('handleMenuToggle');
      e.preventDefault();
      setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = (e) => {
      e.preventDefault();
      setIsMenuOpen(false);
  };

  return (
    <header className="s-header">
      <div className="header-logo">
        <a className="site-logo" href="index.html">
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Logo" />
        </a>
      </div>
      <nav className="header-nav">
        <a href="#0" className="header-nav__close" title="close"><span>Close</span></a>
        <div className="header-nav__content">
          <h3>Navegação</h3>
          <ul className="header-nav__list">
            <li className="current"><a className="smoothscroll"  href="#home" title="home">Home</a></li>
            <li><a className="smoothscroll"  href="#about" title="about">Sobre</a></li>
            <li><a className="smoothscroll"  href="#services" title="services">O que eu faço</a></li>
          </ul>
          <p>"Tudo o que fizerem, façam de todo o coração, como para o Senhor, e não para os homens." (Colossenses 3:23)</p>

          <ul className="header-nav__social">
            <li>
              <a href="mailto:contato@branati.com.br"><i className="fa fa-envelope" aria-hidden="true"></i></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/renato-cunha-msc-54033361"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
            </li>
            <li>
              <a href="https://www.github.com/branati/"><i className="fa fa-github" aria-hidden="true"></i></a>
            </li>
          </ul>

        </div>
      </nav>
      <a className="header-menu-toggle" href="#0" onClick={handleMenuToggle}>
          <span className="header-menu-text">Menu</span>
          <span className="header-menu-icon"></span>
      </a>        
    </header>
  );
}

export default Header;

