import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import Header from './Header';
import Home from './Home';
import About from './About';
import Services from './Services';
// import Works from './Works';
// import Clients from './Clients';
// import Contact from './Contact';
import Footer from './Footer';
import reportWebVitals from './reportWebVitals';
// import Modernizr from './modernizr';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Header />
//     <Home />
//     <About />
//     <Services />

 
//     <Footer />

//   </React.StrictMode>
// );
    // <Contact />
    // <Works />
    // <Clients />
    // <About />
    // <Services />
 // const header_menu_toggle = document.getElementById('.header-menu-toggle');
 // console.log(header_menu_toggle);
 const root = ReactDOM.createRoot(document.getElementById('root'));

 root.render(
    <React.StrictMode>
      <Header />
      <Home />
      <About />
      <Services />
      <Footer />
  
    </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
