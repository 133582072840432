import './base.css';

function Services() {
    return (
        <section id='services' className="s-services">
            <div className="row section-header has-bottom-sep" data-aos="fade-up">
                <div className="col-full">
                    <h3 className="subhead">O que eu faço</h3>
                    <h1 className="display-2">Mostro algumas informações sobre as tecnologias que trabalho</h1>
                </div>
            </div>
            <div className="row services-list block-1-2 block-tab-full">
                <div className="col-block service-item" data-aos="fade-up">
                    <div className="service-icon">
                    <i className="icon-cmd"></i>
                    </div>
                    <div className="service-text">
                        <h3 className="h2">Desenvolvimento Backend</h3>
                        <p>
                            Minha maestria é na linguagem Python, utilizado comumente em muitas empresas.<br/> Atuo, preferencialmente, com desenvolvimento de sistemas backend visando arquitetura escalável e sistemas eficientes. Já desenvolvi aplicações utilizando C, Javascript, React e Rust e tive experiências com Pascal, ASP Classic, C, Visual Basic, C# e .Net.<br/>

                        </p>
                    </div>
                </div>
                <div className="col-block service-item" data-aos="fade-up">
                    <div className="service-icon">
                        <i className="icon-group"></i>
                    </div>
                    <div className="service-text">
                        <h3 className="h2">Automação de Sistemas</h3>
                        <p>
                            Já trabalhei com o Apache Airflow e NodeRed para criação de fluxos para resolução de problemas empresariais. Já atuei com criação e manutenção de scripts de pipelines para CI/CD (Azure, Github, Gitlab e Bitbucket) e também na criação de scripts de Shell para automatizar manutenções de sistemas clusterizados.
                        </p>
                    </div>
                </div>
                <div className="col-block service-item" data-aos="fade-up">
                    <div className="service-icon">
                        <i className="icon-factory"></i>
                    </div>  
                    <div className="service-text">
                        <h3 className="h2">Banco de Dados</h3>
                        <p>
                            Já arquitetei bancos de dados complexos e servidores clusterizados com o PostgreSQL. Já atuei com outros serviços envolvendo SQL e NoSQL como: MariaDB, Microsoft SQL Server, Solr, Opensearch, Elasticsearch e MongoDB.
                        </p>
                    </div>
                </div>
                <div className="col-block service-item" data-aos="fade-up">
                    <div className="service-icon">
                        <i className="icon-earth"></i>
                    </div>
                    <div className="service-text">
                        <h3 className="h2">Integração</h3>
                        <p>
                            Tive experiências com integração de dados com a criação de fluxos automatizados utilizando NodeRed em nuvem.
                        </p>
                    </div>
                </div>
                <div className="col-block service-item" data-aos="fade-up">
                    <div className="service-icon">
                        <i className="icon-cube"></i>
                    </div>
                    <div className="service-text">
                        <h3 className="h2">Devops</h3>
                        <p>
                            Atuei no desenvolvimento de scripts em terraform para automação de deploy de serviços em nuvem e em containers Docker. Atuei e manutenção de scripts CI/CD para deploy de aplicações no Kubernetes (GKE).
                        </p>
                    </div>
                </div>
                <div className="col-block service-item" data-aos="fade-up">
                    <div className="service-icon"><i className="icon-lego-block"></i></div>
                    <div className="service-text">
                        <h3 className="h2">Eletrônica</h3>
                        <p>
                            Desenvolvi placas eletrônicas (Kicad e EasyEDA) e criação de firmwares (C, C++ e Python) para automação e sensoriamento remoto (Internet das Coisas - IoT) de monitoramento de: câmaras de refrigeração de grande porte, sistemas de aquecimento de água de condomínios e acompanhamento de tanques de criação de camarões.
                        </p>
                    </div>
                </div>
            </div> 
        </section> 
    )
}

export default Services;
