import './base.css';

function Home() {
    return (
        <section id="home" className="s-home target-section" data-parallax="scroll" data-image-src="images/hero-bg_new.jpg" data-natural-width="3000" data-natural-height="2000" data-position-y="center">
            <div className="overlay"></div>
            <div className="shadow-overlay"></div>
            <div className="home-content">
                <div className="row home-content__main">
                    <h3>Olá, que bom sua visita!</h3>
                    <h1>
                        Aqui é um espacinho pessoal <br/>
                        mostro um pouco de mim, <br/>
                        do meu trabalho <br/>
                        e dos meus hobbies.
                    </h1>
                    <div className="home-content__buttons">
                        <a href="#contact" className="smoothscroll btn btn--stroke">
                            Precisa de algo?
                        </a>
                        <a href="#about" className="smoothscroll btn btn--stroke">
                            Mais sobre mim
                        </a>
                    </div>
                </div>

                <div className="home-content__scroll">
                    <a href="#about" className="scroll-link smoothscroll">
                        <span>Scroll Down</span>
                    </a>
                </div>

                <div className="home-content__line"></div>

            </div>


            <ul className="home-social">
                <li>
                    <a href="mailto:contato@branati.com.br"><i className="fa fa-envelope" aria-hidden="true"></i><span>Email</span></a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/renato-cunha-msc-54033361"><i className="fa fa-linkedin" aria-hidden="true"></i><span>LinkedIn</span></a>
                </li>
                <li>
                    <a href="https://www.github.com/branati/"><i className="fa fa-github" aria-hidden="true"></i><span>Github</span></a>
                </li>
                
            </ul> 

    </section> 
    )
}

export default Home;